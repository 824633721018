import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs, reactive, watch, defineAsyncComponent, onMounted } from "vue";
import { ElCollapseTransition, ElCarousel, ElCarouselItem, ElEmpty, ElMessageBox, ElNotification } from "element-plus";
import httpApi from "@/api/httpApi";
import { useMessage } from "@/hooks/web/useMessage";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    ElCollapseTransition,
    ElCarousel,
    ElCarouselItem,
    ElEmpty,
    ElNotification,
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    AddressLabel: defineAsyncComponent(() => import("@/components/AddressLabel/")),
    alarmTable: defineAsyncComponent(() => import("@/components/alarmTable/alarmTable.vue"))
  },
  setup() {
    const route = useRoute();
    const message = useMessage();
    const refData = reactive({
      warningData: [],
      warningLen: 0,
      alarmTableRef: null,
      listShow: false,
      refCarousel: null,
      alarmTime: null,
      tableData: [],
      active: 0,
      alarmIds: [],
      ElNotificationList: []
    });
    //关闭当前全部预警
    watch(() => route.path, () => {
      refData.warningLen = 0;
      refData.ElNotificationList.map(item => item.close());
    });
    //上下张控制
    const handleUpDown = type => {
      refData.refCarousel[type]();
    };
    //预警
    const handleWarning = () => {
      if (refData.warningLen && refData.warningData[refData.warningLen - 1]) {
        const obj = ElNotification({
          title: '预警提示',
          type: 'warning',
          message: refData.warningData[refData.warningLen - 1],
          dangerouslyUseHTMLString: true,
          duration: 5000,
          showClose: true
        });
        refData.ElNotificationList.push(obj);
        setTimeout(() => {
          refData.warningLen--;
          handleWarning();
        }, 800);
      }
    };
    //获取实时报警 start
    const getAlramData = async () => {
      refData.alarmTime && clearTimeout(refData.alarmTime);
      refData.warningData = [];
      refData.ElNotificationList = [];
      refData.warningLen = 0;
      const {
        data
      } = await httpApi.pageRealAlarm({
        pageSize: 100,
        pageNo: 1
      });
      if (data) {
        // refData.tableData = data.data;
        refData.tableData = data.list;
        refData.listShow = refData.tableData.length > 0;
        data.list.map(item => {
          !refData.alarmIds.includes(item.id) && (refData.warningLen++, refData.warningData.length < 20 && refData.warningData.push(`${item.objectname}-${item.mdtid}-${item.alarmname}<br />${item.recvtime}`), refData.alarmIds.push(item.id));
        });
        handleWarning();
      }
      refData.alarmTime = setTimeout(() => {
        getAlramData();
      }, 15 * 1000);
    };
    onMounted(() => {
      setTimeout(() => {
        getAlramData();
      }, 1000);
    });
    const handleChange = index => {
      refData.active = index;
    };
    //解除报警
    const deleteRealAlarm = async () => {
      const {
        id,
        objectid,
        alarmtype,
        alarmname,
        mdtid
      } = refData.tableData[refData.active];
      refData.alarmIds = refData.alarmIds.filter(item => id != item.id);
      ElMessageBox.confirm(`是否确认处理“${mdtid}设备-${alarmname}”`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(async () => {
        const {
          code,
          msg
        } = await httpApi.deleteRealAlarm({
          alarmid: id,
          objectid,
          alarmtype
        });
        message[code == 0 ? "success" : "warning"](code == 0 ? "设置成功" : msg);
        code == 0 && getAlramData();
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //批量处理
    const handleBatch = () => {
      refData.alarmTableRef.visible = true;
      refData.alarmTableRef.tableData = refData.tableData;
    };
    //获取实时报警 end
    return {
      ...toRefs(refData),
      handleUpDown,
      deleteRealAlarm,
      handleChange,
      handleBatch
    };
  }
});